import { Box, Container, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { eventeerStyles } from '../../../../utils/hooks/useApplyStyles';
import HubspotForm from "react-hubspot-form";

const NominationForm = ({submitNominationRef}) => {
    const style = eventeerStyles();
    const classes = style();
    const [formKey, setFormKey] = useState(1);
    useEffect(() => {
        window.addEventListener('message', event => {
            if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                setTimeout(() => setFormKey(key => key + 1), 10000);
                window.scrollTo(0, 0);
            }
         });
      }, []);
    return (
        <Box id="nominee-form" ref={submitNominationRef} className={classes.nomnineForm}>
            <Container maxLength="lg">
                <Box className={classes.nomnineFormWrap}>
                    <Typography variant="h2" align='center'>
                    Submit Your Event to Eventeer Awards 2025
                    </Typography>
                    <Typography variant="body1" align='center'>
                    Nominate your event today!
                    </Typography>
                    <Box className={classes.nomnineFormBox}>
                        <HubspotForm
                            region="na1"
                            portalId="2978594"
                            formId="684c8e62-0d12-4d34-a71f-9322584c6e76"
                            loading={<div></div>}
                            key={formKey}
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default NominationForm