import { Box, Container, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, Button } from '@material-ui/core'
import React from 'react'
import { eventeerStyles } from '../../../../utils/hooks/useApplyStyles'
import { Link, useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LazyLoad from 'react-lazyload';
//import honorAward from '../../../../assets/images/honor-winner.png';
import honorAward2024 from '../../../../assets/Eventeer2025/2nd-sec-img.png'


const Honor = ({ submitNomination }) => {

    const style = eventeerStyles();
    const classes = style();

    return (
        <Box className={classes.honor}>
            <Container maxWidth="lg">
                <Box className={classes.honorBG}>
                    <Grid container alignItems='center'justifyContent='center' spacing={4}>
                        <Grid item lg={5}>
                            <LazyLoad height={500}>
                                <Box className={classes.honorAwardImage}>
                                    <img src={honorAward2024} alt="Eventeer honour award" />
                                </Box>
                            </LazyLoad>
                        </Grid>
                        <Grid item lg={7}>
                            <Box className={classes.honorText}>
                                <Typography variant="h1" component="h2" className={classes.eventeerTitle}>
                                Why Nominate?
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Gain industry recognition and prestige."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Celebrate your team’s innovation and creativity."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Amplify your event success through Eventeer’s digital campaign."
                                        />
                                    </ListItem>
                                </List>
                                <Box className={classes.bannerEvneerBtns} style={{ marginTop: "30px" }}>
                                    <Link to="/eventeer-awards-nomination"><Button variant="contained" color="primary" onClick={submitNomination}>Find Your Category</Button></Link>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default Honor