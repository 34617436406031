import React from "react";
import { Box, Grid, Typography, Container } from '@material-ui/core';
import { footerStyles } from "../../utils/hooks/useApplyStyles";
import { CategoryProvider } from '@mui-treasury/components/menu/category';
import LazyLoad from 'react-lazyload';
import { useFirebaseBtnStyles } from '@mui-treasury/styles/button/firebase';
import soc2 from '../../assets/images/SOC 2.webp'
import VPAT from '../../assets/images/VPAT-compliance-1.svg'
import BIMI from '../../assets/images/BIMI-badge-1.svg'
import tx from '../../assets/images/tx-ramp-1.svg'
import GDPR from '../../assets/images/GDPR.webp'
import CCPA from '../../assets/images/CCPA.webp'
import ISO from '../../assets/images/iso.webp'
import CPA from '../../assets/images/cpa.webp'
import capterra from '../../assets/images/capterra.webp'
import Gartner from '../../assets/images//Gartner-logo-footer.svg'
import G2 from '../../assets/images/g2_logo.svg'
import FB from '../../assets/images/001_facebook.svg'
import LinkedIn from '../../assets/images/010_linkedin.svg'
import Twitter from '../../assets/images/013_twitter.svg'
import Instagram from '../../assets/images/instagram.svg'
//import Pinterest from '../../assets/images/pinterest.webp'
import Slack from '../../assets/images/slack.webp'


export default function ConnectSecFooter() {
    const styles1 = useFirebaseBtnStyles();
    const styles = footerStyles();
    const classes = { ...styles1, ...styles() }

    return (
        <>
            <Box className={classes.femails}>
                <CategoryProvider >
                    <ul className={classes.mailList}>
                        <li>  <Typography component="span" variant='h6'>Sales: </Typography><Typography component="a" variant='h6' href="mailto:sales@vfairs.com" color="primary">sales@vfairs.com</Typography></li>
                        <li>  <Typography component="span" variant='h6'>Marketing: </Typography><Typography component="a" variant='h6' href="mailto:marketing@vfairs.com" color="primary">marketing@vfairs.com</Typography></li>
                        <li>  <Typography component="span" variant='h6'>Support: </Typography><Typography component="a" variant='h6' href="mailto:support@vfairs.com" color="primary">support@vfairs.com</Typography></li>
                        <li>  <Typography component="span" variant='h6'>RFPs: </Typography><Typography component="a" variant='h6' href="mailto:rfp@vfairs.com" color="primary">rfp@vfairs.com</Typography></li>
                        <li>  <Typography component="span" variant='h6'>Partners: </Typography><Typography component="a" variant='h6' href="mailto:partners@vfairs.com" color="primary">partners@vfairs.com</Typography></li>
                    </ul>
                </CategoryProvider>
            </Box>
            <Box className={classes.fmidFooter}>
                <Container maxWidth="false" disableGutters="false">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} className={classes.mobJustify} >
                            <CategoryProvider>
                                <Typography variant="h6" component="p" align="center" style={{ marginTop: 0 }}>Compliance</Typography>
                            </CategoryProvider>
                            <ul className={classes.compliances}>
                                <li>
                                    <LazyLoad height={40}>
                                        <img src={ISO} alt="comliance" />
                                    </LazyLoad>
                                </li>
                                <li>
                                    <LazyLoad height={40}>
                                        <img src={CPA} alt="comliance" />
                                    </LazyLoad>
                                </li>
                                <li>
                                    <LazyLoad height={40}>
                                        <img src={CCPA} alt="comliance" />
                                    </LazyLoad>
                                </li>
                                <li>
                                    <LazyLoad height={40}>
                                        <img src={GDPR} alt="comliance" />
                                    </LazyLoad>
                                </li>
                                <li>
                                    <LazyLoad height={40}>
                                        <img src={soc2} alt="Comliance Logo" />
                                    </LazyLoad>
                                </li>
                                <li>
                                    <LazyLoad height={40}>
                                        <img src={VPAT} alt="Comliance Logo" />
                                    </LazyLoad>
                                </li>
                                <li>
                                    <LazyLoad height={40}>
                                        <img src={BIMI} alt="Comliance Logo" />
                                    </LazyLoad>
                                </li>
                                <li>
                                    <LazyLoad height={40} >
                                        <img src={tx} alt="Comliance Logo" />
                                    </LazyLoad>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.findUs}>
                                <CategoryProvider>
                                    <Typography variant="h6" component="p" style={{ marginTop: 0 }}>Read Reviews</Typography>
                                </CategoryProvider>
                                <ul className={classes.otherLogo}>
                                    <li><a rel="nofollow" href="https://www.g2.com/products/vfairs/reviews" target="__blank">
                                        <LazyLoad height={40}>
                                            <img src={G2} alt="G2 logo"/>
                                        </LazyLoad>
                                        </a>
                                    </li>
                                    <li><a rel="nofollow" href="https://www.capterra.com/p/149104/vFairs/" target="__blank">
                                        <LazyLoad height={40}>
                                            <img src={capterra} alt="Capterra logo"/>
                                        </LazyLoad>
                                        </a>
                                    </li>
                                    <li><a rel="nofollow" href="https://www.gartner.com/reviews/market/event-technology-platforms/vendor/vfairs/product/vfairs" target="__blank">
                                        <LazyLoad height={40}>
                                            <img src={Gartner} alt="Gartner"/>
                                        </LazyLoad>
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <div className={classes.connectVfairs}>
                                <CategoryProvider>
                                    <Typography variant="h6" component="p" style={{ marginTop: 0 }}>Connect with vFairs</Typography>
                                </CategoryProvider>
                                <div className={classes.socialLinks}>
                                    <LazyLoad height={40}>
                                        <a rel="noopener noreferrer nofollow" href="https://www.facebook.com/vfairs" target="__blank">
                                            <img src={FB} alt="vFairs Facebook logo"/>
                                        </a>
                                        <a rel="noopener noreferrer nofollow" href="https://www.linkedin.com/company/vfairs" target="__blank">
                                            <img src={LinkedIn} alt="LinkedIn Facebook logo"/>
                                        </a>
                                        <a rel="noopener noreferrer nofollow" href="https://twitter.com/vfairs" target="__blank">
                                            <img src={Twitter} alt="Twitter Facebook logo"/>
                                        </a>
                                        <a rel="noopener noreferrer nofollow" href="https://www.instagram.com/vfairs/" target="__blank">
                                            <img src={Instagram} alt="Instagram Facebook logo"/>
                                        </a>
                                        {/* <a rel="noopener noreferrer nofollow" href="https://www.pinterest.com/vFairs_Official/" target="__blank">
                                            <img src={Pinterest} alt="Pinterest Facebook logo"/>
                                        </a> */}
                                        <a href="https://www.vfairs.com/slack-community/" target="__blank">
                                                <img  src={Slack} alt="Slack Facebook logo"/>
                                        </a>
                                    </LazyLoad>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};