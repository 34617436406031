import React, { useEffect,useRef,useState }  from 'react';
import DiscoverFooter from '../../Footer/Footer'
import NavigationBar from '../../Header/Navbar'
import DiscoverCategoriesSlider from './EventeerSection/DiscoverCategoriesSlider'
import { eventeerStyles,modalStyles } from '../../../utils/hooks/useApplyStyles';
import NominationForm from './EventeerSection/NominationForm'
import { useLocation } from "react-router-dom";


const EventeerAwardsNomination = () => {

    const search = useLocation().search;
    const getvoteQuery = new URLSearchParams(search).get('vote');

    const [open, setOpen] = useState(getvoteQuery && getvoteQuery === 'confirmed' ? true : false);
    
    const styles1 = eventeerStyles();
    const styles3 = modalStyles();
    const classes = { ...styles3(), ...styles1() }
    useEffect(() => {
        window.scrollTo(0, 0);

    })

    return (
        <div className="application">
            <NavigationBar/>
            <NominationForm/>
            {/* <DiscoverCategoriesSlider /> */}
            {/* <WinnerGet /> */}
            {/* <MoreEvents /> */}
            {/* <QnA /> */}
            <DiscoverFooter />
            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={`${classes.modalBox} ${classes.likeModalBox}`}>
                        <LazyLoad height={300}>
                            <img src={likeThumbs} alt="likes" />
                        </LazyLoad>                        
                        <Box className={classes.voteContents}>
                            <Typography variant='h2'>Your vote has been confirmed!</Typography>
                            <Typography variant='body1'>Explore more categories and nominees to vote your favorite one.</Typography>
                            <div className={classes.voteContentBtn}>
                                <Button variant="contained" color="primary" onClick={handleClose}>Explore More</Button>
                            </div>
                        </Box>
                    </Box>
                </Fade>
            </Modal> */}
        </div>
    )
}

export default EventeerAwardsNomination